
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { images, square, triangle, helpCircleOutline, settingsOutline, informationCircleOutline, } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      images, 
      square, 
      triangle,
      helpCircleOutline,
      settingsOutline,
      informationCircleOutline
    }
  }
}
