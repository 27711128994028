<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">

        <ion-tab-button tab="about" href="/about">
          <ion-icon :icon="informationCircleOutline" />
          <ion-label>About</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="map" href="/map">
          <ion-icon :icon="images" />
          <ion-label>Map</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="help" href="/help">
          <ion-icon :icon="helpCircleOutline" />
          <ion-label>Help</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { images, square, triangle, helpCircleOutline, settingsOutline, informationCircleOutline, } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      images, 
      square, 
      triangle,
      helpCircleOutline,
      settingsOutline,
      informationCircleOutline
    }
  }
}
</script>