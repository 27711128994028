import { createRouter, createWebHistory } from '@ionic/vue-router';
// import Home from '../views/Map60.vue'
import Tabs from '../views/Tabs.vue'

const routes = [
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'map'
      },
      {
        path: 'map',
        name: 'map',
        component: () => import('@/views/MapTab.vue')
      },
      {
        path: 'help',
        component: () => import('@/views/Help.vue')
      },
      {
        path: 'about',
        component: () => import('@/views/About.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
